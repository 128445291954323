import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { Tag, TagList } from '../components/tags';
import { makeSectionId } from '../components/jumpboxlink';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Checklist } from '../components/checklist';
import { ServiceJumpTable } from '../components/servicejumptable';
import Seo from '../components/seo';

function SRM({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        // 0
        {
            title: t('application_security_title', 'Application Security'),
            desc: t(
                'application_security_desc',
                'Vom sicheren Entwicklungsprozess bis zu konkreten Secure Coding Guidelines'
            ),
            labels: [],
        },
        // 1
        {
            title: t('operational_security_title', 'Operational Security'),
            desc: t('operational_security_desc', 'Bullet-Proof System Config'),
            labels: [],
        },
        // 2
        {
            title: t('threat_modeling_title', 'Threat Modeling'),
            desc: t(
                'threat_modeling_desc',
                'Bedrohungen bekämpfen, bevor etwas passiert'
            ),
            labels: [],
        },
        // 3
        {
            title: t('cloud_security_title', 'Cloud Security'),
            desc: t(
                'cloud_security_desc',
                'Security - in der Cloud erst recht'
            ),
            labels: [],
        },
        // 4
        {
            title: t(
                'bsi_it_grundschutz_title',
                'Security nach BSI IT-Grundschutz-Kompendium'
            ),
            desc: t(
                'bsi_it_grundschutz_desc',
                'Security nach BSI IT-Grundschutz-Kompendium aufbauen und managen'
            ),
            labels: [],
        },
        // 5
        {
            title: t('asset_security_rating_title', 'Asset Security Rating'),
            desc: t(
                'asset_security_rating_desc',
                'Security Status auf einen Blick'
            ),
            labels: [],
        },
        // 6
        {
            title: t('iso_27001_2022_title', 'ISO 27001:2022'),
            desc: t(
                'iso_27001_2022_desc',
                'Informationssicherheitsmanagementsystem'
            ),
            labels: [],
        },
        // 7
        {
            title: t('nis2_readiness_title', 'NIS2 Readiness Check'),
            desc: t(
                'nis2_readiness_desc',
                'Optimieren Sie Ihre NIS 2-Konformität mit unserem maßgeschneiderten NIS2 Readiness Check'
            ),
            labels: [],
        },
        // 8
        {
            title: t('nis2_content_title', 'NIS2 Toolkit'),
            desc: t(
                'nis2_content_desc',
                'Stärken Sie Ihre Cybersicherheit mit unserem NIS2 Plattform-Paket'
            ),
            labels: [],
        },
        // 9
        {
            title: t('cybertrust_label_title', 'Cyber Trust Austria Label'),
            desc: t(
                'cybertrust_label_desc',
                'Das österreichische Gütesiegel für Cybersicherheit'
            ),
            labels: [],
        },
    ];
    const overview_content = [
        {
            title: t('content_library_title', 'Content Bibliothek'),
            desc: t(
                'content_library_desc',
                'Sofort einsatzbereite und erprobte Inhalte, um Ihre Sicherheits- und Compliance-Anforderungen zu unterstützen'
            ),
            labels: [],
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title="Security Controls Management"
                    text={[
                        t(
                            'subtitle',
                            'Entwicklung und Betrieb proaktiv absichern, damit Schwachstellen erst gar nicht entstehen.'
                        ),
                    ]}
                    image={
                        <StaticImage
                            src="../images/headers/srm.jpg"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Security Controls Management')} />
            <PageSection size="big">
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="intro_1">
                        Einfache Identifikation, Verwaltung und zentrale
                        Steuerung von passenden Security Controls für kritische
                        Assets.
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="intro_2">
                        Wird Security nur als “Afterthought” behandelt, führt
                        dies zu viel unnötigem Stress, hohen Kosten und
                        unzähligen Problemen auf lange Sicht. Daher muss
                        Security so früh als möglich im Lebenszyklus eines
                        Assets betrachtet werden - ganz nach der “Shift left”
                        Philosophie. Das condignum Security Controls Management
                        hilft Ihnen die passenden Security Controls für jede
                        Herausforderung zu identifizieren, ihre Umsetzung zu
                        Steuern und über den gesamten Lebenszyklus hinweg
                        nachzuhalten.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" paddingBottom={false} />
            <ServiceJumpTable
                items={overview_content}
                type="platform-content"
                paddingTop={false}
            />

            <LabeledSection
                title={overview[0].title}
                subtitle={overview[0].desc}
                labels={overview[0].labels}
            >
                <PageText>
                    <Trans i18nKey="application_security_col1">
                        Ausgewählte Secure Coding Maßnahmen und Secure
                        Design Best-Practices werden für jede
                        Applikation, abhängig von ihren individuellen
                        Eigenschaften, identifiziert und können
                        strukturiert und nachvollziehbar in die
                        Entwicklung eingesteuert werden. Durch
                        detaillierte inhaltliche Anleitungen ist weder
                        für die Identifikation, noch die Umsetzung,
                        eigenes Security Know-How erforderlich.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="application_security_col2">
                        Darüber hinaus wird durch zielgerichtete
                        prozessuale Maßnahmen ein sicherer
                        Entwicklungsprozess etabliert und laufend
                        optimiert. Und mithilfe individualisierbarer
                        Auswertungsmöglichkeiten und Dashboards ist das
                        vorhandene Applikationsportfolio und der
                        Security Status der darin enthaltenen
                        Anwendungen jederzeit im Blick.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'application_security_check_1',
                            'Übersicht über das Applikationsportfolio und dessen Security Status (inkl. Trendanalysen)'
                        ),
                        t(
                            'application_security_check_2',
                            'Laufende Optimierung des Secure Software Development Lifecycles'
                        ),
                        t(
                            'application_security_check_3',
                            'Know-How Aufbau durch angeleitetes “Learning by Doing” für technische Mitarbeiter'
                        ),
                        t(
                            'application_security_check_4',
                            'Spezifisch vorgefilterte und priorisierte Secure Coding Maßnahmen und Secure Design Best-Practices'
                        ),
                        t(
                            'application_security_check_5',
                            'Detaillierte inhaltliche Anleitungen und Codebeispiele von Security Experten '
                        ),
                        t(
                            'application_security_check_6',
                            'Werkzeuge zur strukturierten und nachvollziehbaren Steuerung der Umsetzung'
                        ),
                    ]}
                />
            </LabeledSection>

            <LabeledSection
                title={overview[1].title}
                subtitle={overview[1].desc}
                labels={overview[1].labels}
                isDark
            >
                <PageText>
                    <Trans i18nKey="operational_security_col1">
                        Ausgewählte Security Hardening Maßnahmen und
                        Secure Configuration Best-Practices werden für
                        alle Komponenten des IT-Betriebs und der
                        IT-Infrastruktur, abhängig von ihren
                        individuellen Eigenschaften, identifiziert und
                        können strukturiert und nachvollziehbar
                        umgesetzt werden.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="operational_security_col2">
                        Durch detaillierte inhaltliche Anleitungen ist
                        weder für die Identifikation, noch die
                        Umsetzung, eigenes Security Know-How
                        erforderlich. Und mithilfe individualisierbarer
                        Auswertungsmöglichkeiten und Dashboards ist der
                        laufende IT-Betrieb und der Security Status
                        aller Komponenten jederzeit im Blick.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'operational_security_check_1',
                            'Übersicht über alle Komponenten des laufenden IT-Betriebs und deren Security Status (inkl. Trendanalysen)'
                        ),
                        t(
                            'operational_security_check_2',
                            'Laufende Steigerung des Security Niveaus durch gezielte Optimierungsmaßnahmen'
                        ),
                        t(
                            'operational_security_check_3',
                            'Know-How Aufbau durch angeleitetes “Learning by Doing” für technische Mitarbeiter'
                        ),
                        t(
                            'operational_security_check_4',
                            'Spezifisch vorgefilterte und priorisierte Security Hardening Maßnahmen und Config Best-Practices'
                        ),
                        t(
                            'operational_security_check_5',
                            'Detaillierte inhaltliche Anleitungen und Konfigurationsbeispiele von Security Experten'
                        ),
                        t(
                            'operational_security_check_6',
                            'Werkzeuge zur strukturierten und nachvollziehbaren Steuerung der Umsetzung'
                        ),
                    ]}
                />
            </LabeledSection>

            <LabeledSection
                title={overview[2].title}
                subtitle={overview[2].desc}
                labels={overview[2].labels}
            >
                <PageText>
                    <Trans i18nKey="threat_modeling_col1">
                        Mögliche Bedrohungen und deren Auswirkung auf
                        Applikationen, IT-Betrieb und Infrastruktur
                        werden identifiziert und Maßnahmen zur
                        proaktiven, strukturierten und nachvollziehbaren
                        Mitigierung vorgeschlagen. Durch detaillierte
                        inhaltliche Anleitungen ist weder für die
                        Identifikation, noch die Mitigierung, eigenes
                        Security Know-How erforderlich.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'threat_modeling_col2_1',
                            'Überblick relevanter Bedrohungen auf Applikationen, IT-Betrieb und Infrastruktur inkl. technischer Risikoeinschätzung.'
                        ),
                        t(
                            'threat_modeling_col2_2',
                            'Priorisierte Maßnahmen zur proaktiven, strukturierten und nachvollziehbaren Mitigierung'
                        ),
                        t(
                            'threat_modeling_col2_3',
                            'Detaillierte inhaltliche Anleitungen zur Maßnahmenumsetzung'
                        ),
                    ]}
                />
            </LabeledSection>

            <LabeledSection
                title={overview[3].title}
                subtitle={overview[3].desc}
                labels={overview[3].labels}
                isDark
            >
                <PageText>
                    <Trans i18nKey="cloud_security_col1">
                        Ausgewählte Security Best-Practices werden für
                        den Einsatz und die Verwendung von
                        Cloud-Technologien, abhängig von ihren
                        individuellen Eigenschaften, identifiziert und
                        können strukturiert und nachvollziehbar
                        Umgesetzt werden. Durch detaillierte inhaltliche
                        Anleitungen ist weder für die Identifikation,
                        noch die Umsetzung, eigenes Security Know-How
                        erforderlich.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'cloud_security_col2_1',
                            'Spezifisch vorgefilterte Security Best-Practices - sowohl technologieübergreifend, als auch technologiespezifisch für AWS und MS 365/Azure.'
                        ),
                        t(
                            'cloud_security_col2_2',
                            'Werkzeuge zur strukturierten und nachvollziehbaren Steuerung der Umsetzung'
                        ),
                        t(
                            'cloud_security_col2_3',
                            'Detaillierte Inhaltliche Anleitungen zur Maßnahmenumsetzung'
                        ),
                    ]}
                />
            </LabeledSection>

            <LabeledSection
                title={overview[4].title}
                subtitle={overview[4].desc}
                labels={overview[4].labels}
            >
                <PageText>
                    <Trans i18nKey="bsi_it_grundschutz_col1">
                        Die Sicherheitsmaßnahmen des BSI
                        IT-Grundschutz-Kompendiums werden entsprechend
                        der individuellen Schutzbedarfsanforderungen
                        übersichtlich zugänglich gemacht und können
                        strukturiert und nachvollziehbar umgesetzt
                        werden. Zudem kann der aktuelle Umsetzungsstand
                        jederzeit on-demand visualisiert und der
                        Trendverlauf eingesehen werden.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'bsi_it_grundschutz_col2_1',
                            'Vorgefilterte Security Maßnahmen des BSI IT-Grundschutz-Kompendiums'
                        ),
                        t(
                            'bsi_it_grundschutz_col2_2',
                            'Werkzeuge zur strukturierten und nachvollziehbaren Steuerung der Umsetzung'
                        ),
                        t(
                            'bsi_it_grundschutz_col2_3',
                            'Übersicht des aktuellen Umsetzungsstandes'
                        ),
                        t(
                            'bsi_it_grundschutz_col2_4',
                            'Trendanalysen'
                        ),
                    ]}
                />
            </LabeledSection>

            <LabeledSection
                title={overview[5].title}
                subtitle={overview[5].desc}
                labels={overview[5].labels}
                isDark
            >
                <PageText>
                    <Trans i18nKey="asset_security_rating_col1">
                        Das Security Niveau der Organisationsassets ist
                        stets auf einen Blick ersichtlich und
                        vergleichbar. Sowohl jederzeit aktuell als auch
                        im zeitlichen Verlauf.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="asset_security_rating_col2">
                        Ermöglicht wird dies durch die Berechnung eines
                        einfachen Security Ratings für jedes Asset auf
                        einer Skala von A - E, auf Basis des
                        Umsetzungsstatus der relevanten Security
                        Maßnahmen. So wird der Überblick stets bewahrt
                        und dringliche Handlungsfelder werden effizient
                        lokalisiert.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'asset_security_rating_col3_1',
                            'Security Rating auf einer Skala von A - E für einzelne Assets und deren Teilbereiche'
                        ),
                        t(
                            'asset_security_rating_col3_2',
                            'Security Rating Vergleich zwischen Assets'
                        )
                    ]}
                />
            </LabeledSection>

            {/* ISO 27001:2022 */}

            <LabeledSection
                title={overview[6].title}
                subtitle={overview[6].desc}
                labels={overview[6].labels}
            >
                <PageText>
                    <Trans i18nKey="iso_27001_2022_p1">
                        Erhalten Sie alle Anforderungen der ISO/IEC 27001:2022 zum Aufbau und
                        Betrieb eines Informationssicherheits-managementsystems (ISMS). Unsere
                        Plattform liefert Ihnen alle notwendigen Funktionen, von der Durchführung
                        einer GAP Analyse, der Erstellung einer Anwendbarkeitserklärung (SOA) bis
                        hin zur Maßnahmennachhaltung und laufenden Compliance-Auswertung.
                    </Trans>
                </PageText>
            </LabeledSection>

            {/* NIS2 Readiness */}

            <LabeledSection
                title={overview[7].title}
                subtitle={overview[7].desc}
                labels={overview[7].labels}
                isDark
            >
                <PageText>
                    <Trans i18nKey="nis2_readiness_p1">
                        Unsere Reifegradbewertung ermöglicht Ihnen, einen klaren und strukturierten
                        Implementierungsplan zu entwickeln.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'nis2_readiness_c1',
                            'Vollständiger NIS 2 Readiness Check: Eine umfassende Überprüfung Ihrer Bereitschaft für die NIS 2-Richtlinie.'
                        ),
                        t(
                            'nis2_readiness_c2',
                            'Zielgerichtete Analyse zusätzlicher NIS-2-Anforderungen: Vergleich und Kontrastierung Ihrer bestehenden Prozesse mit den neuen Anforderungen der NIS 2-Richtlinie.'
                        ),
                        t(
                            'nis2_readiness_c3',
                            'Technische Standardsanalyse: Bewertung der Implementierung und Wirksamkeit der technischen Standards innerhalb Ihres Unternehmens.'
                        ),
                    ]}
                />
            </LabeledSection>

            {/* NIS2 */}

            <LabeledSection
                title={overview[8].title}
                subtitle={overview[8].desc}
                labels={overview[8].labels}
            >
                <PageText>
                    <Trans i18nKey="nis2_content_p1">
                        Das NIS2 Dokumenten-Toolkit aus der condignum Bibliothek ist Ihre Schlüsselressource Ihrer Cybersicherheitsmaßnahmen
                        und zur Erfüllung der NIS2-Richtlinie. Unsere umfangreiche Sammlung praxisorientierter Vorlagen und klarer Anleitungen unterstützt
                        Ihr Unternehmen dabei, eine nachhaltige und strukturierte NIS2-Compliance zu erreichen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis2_content_p2">
                        Mit dem NIS2 Toolkit decken Sie alle relevanten Aspekte der NIS2-Richtlinien ab und erfüllen gesetzliche Anforderungen effizient und effektiv. 
                        Die Kombination mit der condignum Plattform optimiert Ihre Sicherheitsstrategie und stärkt das Vertrauen Ihrer Kunden und Partner. 
                        Profitieren Sie von unserer Expertise und bewährten Methoden, um Ihre Cybersicherheitsziele schnell und zuverlässig zu erreichen. 
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="nis2_content_p3">
                        Setzen Sie auf das NIS2 Toolkit und heben Sie Ihre Organisation auf ein neues Sicherheitsniveau.
                    </Trans>
                </PageText>
            </LabeledSection>

            {/* Cyber Trust Austria Label */}
            <LabeledSection
                title={overview[9].title}
                subtitle={overview[9].desc}
                labels={overview[9].labels}
                isDark
            >
                <PageText>
                    <Trans i18nKey="cybertrust_label_p1">
                        Durch die Erlangung des Cyber Trust Austria Label - dem österreichischen Gütesiegel
                        für Cybersicherheit - können Sie zeigen, dass Ihnen Security wichtig ist und Ihre
                        Kunden und Partner Ihnen vertrauen können.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="cybertrust_label_p2">
                        Die condignum Plattform unterstützt Sie bei der Vorbereitung auf diese aussagekräftige
                        Zertifizierung.
                    </Trans>
                </PageText>
            </LabeledSection>


            {/* Content Library  */}

            <LabeledSection
                title={overview_content[0].title}
                subtitle={overview_content[0].desc}
            >
                <PageText>
                    <Trans i18nKey="content_library_col1">
                        Die condignum Content Bibliothek erweitert die
                        Module Security Compliance Management und
                        Security Controls Management um sofort
                        anwendbare Anforderungs- und Maßnahmenkataloge.
                        Die Kataloge enthalten dabei sowohl bekannte
                        Standards, Normen und Gesetze, als auch
                        etablierte Security Best Practices zur
                        Entwicklung und Härtung von IT-Systemen
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="content_library_col2">
                        Die Inhalte werden durch unsere Experten
                        kontinuierlich erweitert und aktualisiert.
                    </Trans>
                </PageText>
            </LabeledSection>

            <ContactSection isDark={false} />
        </Layout>
    );
}

function LabeledSection({
    children,
    title,
    subtitle,
    labels = [],
    isDark = false,
}) {
    return (
        <PageSection size="big" id={makeSectionId(title)} isDark={isDark}>
            <PageTitle className="md:flex relative">
                {title}
                <TagList
                    as="small"
                    labels={labels}
                    className="hidden md:flex ml-2"
                >
                    {labels.map(label => (
                        <Tag key={label} as="span">
                            {label}
                        </Tag>
                    ))}
                </TagList>
            </PageTitle>
            <PageSubtitle>{subtitle}</PageSubtitle>
            <TagList labels={labels} className="-mt-5 mb-8 md:hidden">
                {labels.map(label => (
                    <Tag key={label}>{label}</Tag>
                ))}
            </TagList>
            {children}
        </PageSection>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["security-controls-management"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SRM;
